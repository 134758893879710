/**
 * Created by Anatoly on 27.09.2016.
 */

/**
 * This is Array.prototype.find implementation taken from here: http://stackoverflow.com/a/35135200/1040070
 * Will be used in case when native Array.prototype.find unavailable
 * */
const setMethodArrayFind = function() {
	if (!Array.prototype.find) {
		Object.defineProperty(Array.prototype, "find", {
			value: function (predicate) {
				if (this === null) {
					throw new TypeError('Array.prototype.find called on null or undefined');
				}
				if (typeof predicate !== 'function') {
					throw new TypeError('predicate must be a function');
				}
				const list = Object(this);
				const length = list.length >>> 0;
				const thisArg = arguments[1];
				let value;

				for (let i = 0; i < length; i++) {
					value = list[i];
					if (predicate.call(thisArg, value, i, list)) {
						return value;
					}
				}
				return undefined;
			}
		});
	}
};

module.exports = setMethodArrayFind;