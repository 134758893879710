/**
 * Created by wert on 12.06.2018
 */

const	SITE_WRAPPER_CLASS_NAME = 'bSiteWrap',
		LOADER_CLASS_NAME		= 'bPageLoaderWrapper';


/** this inserts loader on the page */
export function showLoader(): void {
	const loaderDiv = document.createElement("div");

	loaderDiv.className = LOADER_CLASS_NAME;
	loaderDiv.innerHTML = `<div class="bPageLoader">
		<img class="ePageLoader_logo" src="dist/images/loader/login.svg"/>
		<img class="ePageLoader_spinner" src="dist/images/loader/spin-loader-black.svg"/>
	</div>`;

	const siteWrapper = document.getElementsByClassName(SITE_WRAPPER_CLASS_NAME)[0];
	document.body.insertBefore(loaderDiv, siteWrapper);
}

export function hideLoader(): void {
	const loaderWrapper = document.getElementsByClassName(LOADER_CLASS_NAME)[0];
	if(loaderWrapper) {
		loaderWrapper.outerHTML = "";
	}
}

export function isLoaderVisible(): boolean {
	const loaderWrapper = document.getElementsByClassName(LOADER_CLASS_NAME)[0];
	return !!loaderWrapper;
}
