/**
 * Created by wert on 04.06.2018
 *
 * Stage0 loader. It will be embedded right on index.html after minification
 * This loader perform only following operations:
 * - show loader pic (svg)
 * - determines what to load next
 * - load resource (from CDN with fallback to our server)
 * - hide loader pic
 */

import {startModuleSelector} from "module/helpers/loader_utils";
import {showLoader, hideLoader} from "./global_page_loader";
import {tryApplyPolyfill} from "../polyfills";


export function loadJSScript(scriptUrl: string, onLoad: () => void) {
	const script = document.createElement('script');
	script.onload = function () {
		console.log(`Successfully load ${scriptUrl}`);
		onLoad();
	};

	script.src = scriptUrl;
	document.head.appendChild(script);
}

export function loadStyle(styleUrl: string, onLoad: () => void) {
	const linkElement = document.createElement('link');
	linkElement.rel = 'stylesheet';
	linkElement.type = 'text/css';
	linkElement.href = styleUrl;
	linkElement.onload = () => {
		console.log(`Successfully load style: ${styleUrl}`);
		onLoad();
	};

	document.head.appendChild(linkElement);
}

export function loadJSAndStyle(scriptUrl: string, styleUrl: string, onLoad: () => void) {
	let onLoadState = {
		script: false,
		style: false
	};

	loadJSScript(scriptUrl, () => {
		onLoadState.script = true;
		if(onLoadState.style === true ) {
			onLoad();
		}
	});

	loadStyle(styleUrl, () => {
		onLoadState.style = true;
		if(onLoadState.script === true ) {
			onLoad();
		}
	});
}



console.log(`loader started at ${document.location.hostname}`);

tryApplyPolyfill();

showLoader();

const resourceTable = {
	'module/start_as_app': {
		scripts:	['dist/app.bundle.js'],
		styles:		['dist/app.styles.css']
	},
	'module/start_as_admin': {
		scripts:	['dist/admin.bundle.js'],
		styles:		['dist/admin.styles.css']
	},
	'module/start_as_api_test': {
		scripts:	['dist/api_test.bundle.js'],
		styles:		['dist/api_test.styles.css']
	},
	'module/start_as_bigscreen': {
		scripts:	['dist/bigscreen.bundle.js'],
		styles:		['dist/bigscreen.styles.css']
	},
	'module/start_as_blog': {
		scripts:	['dist/blog.bundle.js'],
		styles:		['dist/blog.styles.css']
	},
	'module/start_as_invite': {
		scripts:	['dist/invite.bundle.js'],
		styles:		['dist/invite.styles.css']
	},
	'module/start_as_password': {
		scripts:	['dist/password.bundle.js'],
		styles:		['dist/password.styles.css']
	},
	'module/start_as_playground': {
		scripts:	['dist/playground.bundle.js'],
		styles:		['dist/playground.styles.css']
	},
	'module/start_as_school': {
		scripts:	['dist/school.bundle.js'],
		styles:		['dist/school.styles.css']
	},
	'module/start_as_www': {
		scripts:	['dist/www.bundle.js'],
		styles:		['dist/www.styles.css']
	},
	'module/start_as_static': {
		scripts:	['dist/static.bundle.js'],
		styles:		['dist/static.styles.css']
	},
    'module/start_as_union': {
        scripts:	['dist/union.bundle.js'],
        styles:		['dist/union.styles.css']
    },
	'module/start_as_tv': {
		scripts:	['dist/tv.bundle.js'],
		styles:		['dist/tv.styles.css']
	}
};

const startModuleName = startModuleSelector(document.location.hostname);
console.log(`will load module for ${startModuleName}`);

const toLoad = resourceTable[startModuleName];
loadJSAndStyle(toLoad.scripts[0], toLoad.styles[0], () => {
	hideLoader();
	console.log('loader stopped');
});

