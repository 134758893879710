const setMethodStringEndsWith = function() {
	if (!String.prototype.endsWith) {
		Object.defineProperty(String.prototype, 'endsWith', {
			value: function(searchString, position) {
				var subjectString = this.toString();
				if (position === undefined || position > subjectString.length) {
					position = subjectString.length;
				}
				position -= searchString.length;
				var lastIndex = subjectString.indexOf(searchString, position);
				return lastIndex !== -1 && lastIndex === position;
			}
		});
	}
};

module.exports = setMethodStringEndsWith;