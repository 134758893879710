/**
 * Created by wert on 17.02.16.
 */

import {object_assign} from "./object_assign";

const 	setMethodArrayFind 			= require('./array_find'),
		setMethodArrayFindIndex 	= require('./array_findIndex'),
		setMethodStringStartsWith 	= require('./string_startsWith'),
		setMethodStringEndsWith 		= require('./string_endsWith'),
		BPromise					= require('bluebird');

export function isPolyfillRequired() {
	return (
		typeof Object.assign !== 'function' ||
		typeof Array.prototype.find !== 'function' ||
		typeof Array.prototype.findIndex !== 'function' ||
		typeof window.Promise === 'undefined'
	);
}


/* Some cool ES6 methods here which undoubtedly should be part of JS. */
export function tryApplyPolyfill(){
    // will add Object.assign if there is no any
    if(!Object.assign) {
        console.info('Polyfilling Object.assign');
        Object.assign = object_assign;
    }

	/** will add Array.prototype.find if there is no any */
	if (!Array.prototype.find) {
		console.info('Polyfilling Array.prototype.find');
		setMethodArrayFind();
	}

	/** will add Array.prototype.findIndex if there is no any */
	if (!Array.prototype.findIndex) {
		console.info('Polyfilling Array.prototype.findIndex');
		setMethodArrayFindIndex();
	}

	if(!window.Promise) {
		console.info('Polyfilling promises with bluebird');
		window.Promise = BPromise;
	}
	
	/** will add String.prototype.startsWith if there is no any */
	if (!String.prototype.startsWith) {
		console.info('Polyfilling String.prototype.startsWith');
		setMethodStringStartsWith();
	}

	/** will add String.prototype.startsWith if there is no any */
	if (!String.prototype.endWith) {
		console.info('Polyfilling String.prototype.endWith');
		setMethodStringEndsWith();
	}
}
