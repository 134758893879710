/**
 * Created by wert on 19.11.15.
 *
 * This file is used during very initial boot and should be as light as possible:
 * - no doubtful dependencies
 * - no non-required methods
 */

const specialModels	= [
		'app',
		'parents',
		'manager',
		'admin',
		'site',
		'www',
		'stage',
		'playground',
		'password',
		'bigscreen',
		'union',
		'isasportpreview',
		'api_test',
		'invite',
		'blog',
		'isaathletics2017',
		'isaathletics2018',
		'tv'
	];

const	defaultModel 	= 'school';

/** Parses domain name to structure */
export function parseDomainName(domainName: string) {
	// http://manager.squard.com → ["manager.squard.com", "manager", undefined|stage, "squard"]
	const external = domainName.match(/([A-z0-9-]+)+(?:.(local|dev|stage|stage1|prod|preprod))?.(squadintouch)\.(com|co\.uk)/);
	
	let model: string;
	if(external[1].substring(0, 10) === 'bigscreen_') {
		model = 'bigscreen';
	} else if (external[1].substring(0, 3) === 'tv_'){
		model = 'tv';
	} else {
		model = external[1];
	}
	
	return {
		fullName: 		external[0],
		model: 			model,
		isStage: 		external[2] === 'stage' || external[2] === 'stage1',
		rootDomain: 	external[3],
		env: 			external[2]
	}
}

/** Returns api endpoint based on given domain name */
export function apiSelector(domainName: string): { main: string, img: string } {
	const { env } = parseDomainName(domainName);
	switch (true) {
		case env === 'stage1':
			return {
				main: 	'//api.stage1.squadintouch.com',
				img: 	'//img.stage1.squadintouch.com'
			};
		case env === 'dev':
			return {
				main: 	`https://api.stage1.squadintouch.com`,
				img: 	'https://img.stage1.squadintouch.com'
			};
		case env === 'local':
			return {
				main:   `//localhost:3000`,
				img: 	'//localhost:3001'
			};
		default:
			return {
				main: 	'//api2' + (env ? '.' + env : '') + `.squadintouch.com`,
				img: 	'//images' + (env ? '.' + env : '') + '.squadintouch.com'
			};
	}
}

/** Chooses module to load based on given domain name */
// Note: If no model is suitable for a domain, then this is the public site of the school, school union or tournament
// Default model calls 'school', but it renders public websites of schools, school unions and tournaments
export function startModuleSelector(domainName: string) {
	const 	parsedDomain 	= parseDomainName(domainName),
			model 			= parsedDomain.model,
			modelToStart 	= specialModels.indexOf(model) !== -1 ? model : defaultModel;

	let startModule = 'module/start_as_' + modelToStart;
	
	// TEST SERVER TEMPORARY SOLUTION
	if(startModule === 'module/start_as_stage') {
		startModule = 'module/start_as_www';
	}

	if(modelToStart === 'isaathletics2017' || modelToStart === 'isaathletics2018'){
		startModule = 'module/start_as_static';
	}

	// TODO: tmp
	if(startModule === 'module/start_as_isasportpreview') {
		startModule = 'module/start_as_union';
	}

	return startModule;
}

export function isDeveloperEnvironment(domainName){
	const parsedDomain = parseDomainName(domainName);
	return ['stage1', 'local', 'dev'].indexOf(parsedDomain.env) !== -1;
}

export function isLocalDeveloperEnvironment(domainName){
	const parsedDomain = parseDomainName(domainName);
	return ['local', 'dev'].indexOf(parsedDomain.env) !== -1;
}

export function getDomainWithoutWWW(){
	const domainParts = document.location.host.split('.');

	// for 'www.something.squadintouch.com'-like domains, skipping 'www'-part
	const domain = domainParts[0] === 'www' ? domainParts[1] : domainParts[0];
	return domain;
}

